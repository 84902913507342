<script setup lang="ts">
import { useLocalePath } from '#i18n'

const menuItems = [
  {
    id: 'game-plan',
    icon: '/img/icons/game-plan-icon@3.webp',
    text: 'Herní plán',
    link: { name: 'levels', query: { t: 'exercise' } },
  },
  {
    id: 'stretching',
    icon: '/img/icons/stretching-icon@3.webp',
    text: 'Protahování',
    link: { name: 'levels', query: { t: 'stretching' } },
  },
]

const userItems = [
  {
    id: 'hallOfFame',
    icon: '/img/icons/podium-icon@3.webp',
    text: 'Síň slávy',
    link: { name: 'hallOfFame' },
  },
  {
    id: 'blog',
    icon: '/img/icons/blog-icon@3.webp',
    text: 'Blog',
    link: { name: 'blog' },
  },
]

const localePath = useLocalePath()

const emit = defineEmits(['toggle'])
const onToggle = () => {
  emit('toggle')
}

const authenticationStore = useAuthenticationStore()
const { isAuthenticated } = authenticationStore
const { user } = storeToRefs(authenticationStore)
</script>

<template>
  <nuxt-link
    v-for="item in menuItems"
    :key="item.id"
    :to="localePath(item.link)"
    class="AppMenu__item flex items-center mb-2"
    @click="onToggle"
  >
    <img
      :src="item.icon"
      alt="Menu icon"
      class="w-8 mr-2"
    >
    <div>{{ $t(item.text) }}</div>
  </nuxt-link>

  <hr
    class="my-6"
  />
  <nuxt-link
    v-for="item in userItems"
    :key="item.id"
    :to="localePath(item.link)"
    class="AppMenu__item flex items-center mb-2"
    @click="onToggle"
  >
    <img
      :src="item.icon"
      alt="Menu icon"
      class="w-8 mr-2"
    >
    <div>{{ $t(item.text) }}</div>
  </nuxt-link>
  <a
    href="https://cdn.hubnuti123.cz/Sixpack-misc/kucharka.pdf"
    class="AppMenu__item flex items-center mb-2"
    target="_blank"
  >
    <img
      src="/img/icons/chef-icon@3.webp"
      alt="Menu icon"
      class="w-8 mr-2"
    >
    <div>{{ $t('Kuchařka') }}</div>
  </a>

  <ClientOnly>
    <template v-if="isAuthenticated">
      <a
        :href="user?.healthPlanPath"
        target="_blank"
        class="AppMenu__item flex items-center mb-2"
      >
        <img
          src="/img/icons/medical-record@3.webp"
          alt="Menu icon"
          class="w-8 mr-2"
        >
        Zdravotní plán
      </a>
    </template>
  </ClientOnly>
</template>
